import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';

import './scss/form-checkbox.scss';

const FormCheckbox = ({
  className,
  disabled,
  label,
  name,
  id,
  checked,
  value,
  onFocus,
  ...otherProps
}) => {
  const [field] = useField({
    name,
    ...otherProps,
  });
  const [isFocused, setFocusState] = useState(false);
  const { isSubmitting, errors, touched } = useFormikContext();

  const handleFieldFocus = () => {
    setFocusState(!isFocused);

    if (onFocus) {
      onFocus();
    }
  };

  return (
    <div className="form-field">
      <div className={classNames('form-checkbox', className && `${className}`)}>
        <input
          {...field}
          className={classNames('form-checkbox__checkbox', {
            disabled,
          })}
          id={id}
          name={name}
          type="checkbox"
          disabled={disabled || isSubmitting}
          onFocus={handleFieldFocus}
          checked={checked}
          value={value}
        />
        <label
          className={classNames('form-checkbox__label', {
            'with-error': errors.academies && touched.academies,
          })}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

FormCheckbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onFocus: PropTypes.func,
};

export { FormCheckbox };
