import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';

import './scss/form-field.scss';

const FormTextArea = ({
  label,
  name,
  disabled,
  onFocus,
  setCurrentSymbolCount,
  maxSymbolCount,
  ...otherProps
}) => {
  const [field, meta] = useField({
    name,
    ...otherProps,
  });
  const [isFocused, setFocusState] = useState(false);
  const { isSubmitting } = useFormikContext();
  const toggleFocusState = () => setFocusState(!isFocused);
  const showError = meta.error && meta.touched;
  const handleFieldFocus = () => {
    toggleFocusState();
    if (onFocus) {
      onFocus();
    }
  };

  const handleFieldBlur = (e) => {
    field.onBlur(e);
    toggleFocusState();
  };

  const handleTextAreaChange = (e) => {
    field.onChange(e);
    setCurrentSymbolCount(e.target.value.length);
  };

  return (
    <div
      className={classNames('form-field', { floated: meta.value || isFocused })}
    >
      <label className="form-field__label" htmlFor={name}>
        {label}
      </label>
      <textarea
        {...field}
        className={classNames('form-field__textarea', {
          error: showError,
        })}
        maxLength={maxSymbolCount}
        onBlur={handleFieldBlur}
        onFocus={handleFieldFocus}
        onChange={handleTextAreaChange}
        id={name}
        disabled={disabled || isSubmitting}
      />
      {showError && <p className="form-field__error-message">{meta.error}</p>}
    </div>
  );
};

FormTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setCurrentSymbolCount: PropTypes.func.isRequired,
  maxSymbolCount: PropTypes.number.isRequired,
  onFocus: PropTypes.func.isRequired,
};

export { FormTextArea };
