import React from 'react';
import PropTypes from 'prop-types';

import ConfirmationIcon from 'images/confirmation-icon.svg';

import './scss/submit-msg-w-icon.scss';
import { SubmitMessage } from './SubmitMessage';

const SubmitMessageWithIcon = ({
  description,
  infoNote,
  infoQuestion,
  title,
}) => {
  return (
    <SubmitMessage>
      <div className="submit-msg-w-icon">
        <figure className="submit-msg-w-icon__icon-wrapper">
          <img
            src={ConfirmationIcon}
            alt=""
            className="submit-msg-w-icon__icon"
          />
        </figure>
        {title && <h3 className="submit-msg-w-icon__title">{title}</h3>}
        <div className="submit-msg-w-icon__text-wrapper">
          {description && (
            <div
              className="submit-msg-w-icon__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          <p className="submit-msg-w-icon__info">
            {infoQuestion && (
              <span className="submit-msg-w-icon__info-highlight">
                {infoQuestion}
              </span>
            )}
            {infoNote && infoNote}
          </p>
        </div>
      </div>
    </SubmitMessage>
  );
};

SubmitMessageWithIcon.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  infoNote: PropTypes.string,
  infoQuestion: PropTypes.string,
};

export { SubmitMessageWithIcon };
