import React from 'react';
import PropTypes from 'prop-types';

import './scss/submit-message.scss';

const SubmitMessage = ({ children }) => (
  <div className="submit-message">{children}</div>
);

SubmitMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SubmitMessage };
