import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';

import './scss/form-field.scss';

const FormField = ({
  label,
  type = 'text',
  name,
  disabled,
  onFocus,
  autoComplete = 'on',
  formFieldRef,
  ...otherProps
}) => {
  const [field, meta] = useField({
    name,
    ...otherProps,
  });
  const [isFocused, setFocusState] = useState(false);
  const { isSubmitting } = useFormikContext();
  const toggleFocusState = () => setFocusState(!isFocused);
  const showError = meta.error && meta.touched;
  const handleFieldFocus = () => {
    toggleFocusState();
    if (onFocus) {
      onFocus();
    }
  };

  const handleFieldBlur = (e) => {
    field.onBlur(e);
    toggleFocusState();
  };

  return (
    <div
      className={classNames('form-field', { floated: meta.value || isFocused })}
    >
      <label className="form-field__label" htmlFor={name}>
        {label}
      </label>
      <input
        {...field}
        className={classNames('form-field__input', {
          error: showError,
        })}
        ref={formFieldRef}
        onBlur={handleFieldBlur}
        onFocus={handleFieldFocus}
        autoComplete={autoComplete}
        type={type}
        id={name}
        disabled={disabled || isSubmitting}
      />
      {showError && <p className="form-field__error-message">{meta.error}</p>}
    </div>
  );
};

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  formFieldRef: PropTypes.shape(),
};

export { FormField };
