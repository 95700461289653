import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import './scss/submit-button.scss';

const SubmitButton = ({ disabled, children, ...otherProps }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <button
      disabled={disabled || isSubmitting}
      type="submit"
      className="btn form-button"
      {...otherProps}
    >
      {children}
    </button>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export { SubmitButton };
