import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';

import iconArrowDown from 'images/select-arrow-down.svg';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import generateUniqueId from 'utilities/generateUniqueId';
import './scss/form-field.scss';

const FormSelect = ({
  label,
  selectLabel,
  name,
  disabled,
  onFocus,
  options,
  selected,
  ...otherProps
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [field, meta] = useField({
    name,
    ...otherProps,
  });
  const [isFocused, setFocusState] = useState(false);
  const { isSubmitting } = useFormikContext();
  const toggleFocusState = () => setFocusState(!isFocused);
  const showError = meta.error && meta.touched;

  const handleFieldChange = (e) => {
    field.onChange(e);
  };

  const handleFieldFocus = () => {
    toggleFocusState();
    if (onFocus) {
      onFocus();
    }
  };

  const handleFieldBlur = (e) => {
    field.onBlur(e);
    toggleFocusState();
  };

  const renderOptions = (optionsList) =>
    optionsList.map((option) => (
      <option value={option} key={generateUniqueId()}>
        {option}
      </option>
    ));

  return (
    <div className={classNames('form-field', 'floated')}>
      <label className="form-field__label" htmlFor={name}>
        {label}
      </label>
      <select
        {...field}
        className={classNames('form-field__select', {
          error: showError,
        })}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        onFocus={handleFieldFocus}
        id={name}
        disabled={disabled || isSubmitting}
      >
        <option>
          {selectLabel
            ? `${translation['button.select']} ${selectLabel.toLowerCase()}`
            : `${translation['button.select']} ${label.toLowerCase()}`}
        </option>
        ) : (
        <option>
          {selectLabel
            ? `Select ${selectLabel.toLowerCase()}`
            : `Select ${label.toLowerCase()}`}
        </option>
        {renderOptions(options)}
      </select>
      <img
        className="form-field__select-icon"
        src={iconArrowDown}
        alt="Select option"
      />
      {showError && <p className="form-field__error-message">{meta.error}</p>}
    </div>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  selectLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
};

export { FormSelect };
