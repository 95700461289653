import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import classNames from 'classnames';

import { FormCheckbox } from 'components/forms';
import 'components/forms/scss/form-checkbox-group.scss';

const FormCheckboxGroup = ({
  selectionTitle,
  groupName,
  options,
  handleFieldFocus,
  className,
}) => {
  const { values, touched, errors } = useFormikContext();
  return (
    <fieldset
      className={classNames('form-checkboxes', className && `${className}`)}
    >
      {selectionTitle && options && options.length > 1 && (
        <legend className="form-checkboxes__title">{selectionTitle}</legend>
      )}
      <FieldArray
        name={groupName}
        render={(arrayHelpers) => (
          <div className="form-checkboxes__group">
            {options.map((option) => (
              <FormCheckbox
                key={option.id}
                onFocus={handleFieldFocus}
                name={groupName}
                value={option.id}
                id={option.id}
                checked={values[groupName].includes(option.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    arrayHelpers.push(option.id);
                  } else {
                    const index = values[groupName].indexOf(option);
                    arrayHelpers.remove(index);
                  }
                }}
                label={option.label}
              />
            ))}
            {touched[groupName] && (
              <p className="form-field__error-message">{errors[groupName]}</p>
            )}
          </div>
        )}
      />
    </fieldset>
  );
};

FormCheckboxGroup.propTypes = {
  selectionTitle: PropTypes.string,
  className: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleFieldFocus: PropTypes.func.isRequired,
};

export { FormCheckboxGroup };
